import { useCartoLayerProps } from '@carto/react-api';
import { useEffect } from 'react';
import {
  CPUGridLayer,
  HeatmapLayer,
  HexagonLayer,
} from '@deck.gl/aggregation-layers';
import { useDispatch, useSelector } from 'react-redux';
import { DataFilterExtension } from '@deck.gl/extensions';
import { addLayer } from '@carto/react-redux';
import { pathOr } from 'ramda';
import { extent as d3Extent } from 'd3-array';
import { scaleLinear } from 'd3-scale';

// constants
import {
  CARTO_MAP_LAYERS,
  GEOJSON_LAYER_MAPPING,
  MOUSE_EVENTS,
} from '@/utils/constants';

// utils
import { getFormatter } from '@/utils/formatters';
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';
import { hasGeoJsonData } from '@/utils/map-utils.js';

const DOUBLE_DASH = '--';

export default function getBuilderLayers(props) {
  return BuilderLayers(props);
}

const BuilderLayers = ({
  mapConfig,
  popupConfigRef,
  setPopupConfig,
  projectViewConfig,
}) => {
  const dsStates = useSelector((state) => state.carto.dataSources);
  const { data, map } = mapConfig || {};

  if (
    !data ||
    !data.datasets ||
    !map ||
    !dsStates ||
    Object.keys(dsStates).length !== data.datasets.length
  ) {
    return [];
  }

  const popupSettings = data.keplerMapConfig.config.popupSettings.layers;
  const rawLayers = data.keplerMapConfig.config.visState.layers;

  const pvMapConfig = projectViewConfig?.mapConfig || {};
  const viewLayersConfig = pvMapConfig.layers || [];
  const indexedViewLayersConfig = Object.fromEntries(
    viewLayersConfig.map((cfg) => [cfg.id, cfg]),
  );
  return map.layers.map((deckLayer) => {
    const rawLayer = rawLayers.find((rawLayer) => rawLayer.id === deckLayer.id);
    const builderLayer = BuilderLayer({
      popupSettings,
      deckLayer,
      rawLayer,
      source: dsStates[rawLayer.config.dataId],
      popupConfigRef,
      setPopupConfig,
      viewLayerConfig: indexedViewLayersConfig[deckLayer.id],
    });
    return builderLayer;
  });
};

const BuilderLayer = ({
  popupSettings,
  deckLayer,
  source,
  popupConfigRef,
  setPopupConfig,
  viewLayerConfig,
  rawLayer,
}) => {
  const dispatch = useDispatch();
  const cartoLayerProps = useCartoLayerProps({ source });
  const layerState = useSelector(
    (state) => state.carto.layers[deckLayer.id] || {},
  );
  const layerPopupConfig = popupSettings[deckLayer.id];

  const config = {
    ...cartoLayerProps,
    ...deckLayer.props,
    pickable: true,
    autoHighlight: true,
  };
  config.data = deckLayer.props.data; // don't know why we need to force this

  let isVisible = deckLayer.props.visible;
  if (viewLayerConfig?.visible !== undefined) {
    isVisible = viewLayerConfig.visible;
  } else if (layerState?.visible !== undefined) {
    isVisible = layerState.visible;
  }
  config.visible = isVisible;

  useEffect(() => {
    dispatch(
      addLayer({
        id: deckLayer.id,
        source: source.id,
        layerAttributes: {
          title: deckLayer.props.cartoLabel,
          visible: isVisible,
          showOpacityControl: false,
          switchable: true,
          legend: {},
        },
      }),
    );
  }, [dispatch]);

  const formatFunctions = {};
  const buildInfoHTML = (
    { layer, object: feature, coordinate },
    trigger,
    fields,
  ) => {
    const measures = fields.map((fieldConfig) => {
      const aggregation = fieldConfig.spatialIndexAggregation;
      let displayName = fieldConfig.customName || fieldConfig.name;
      let value;

      if (aggregation !== undefined) {
        value = feature?.properties[`${fieldConfig.name}_${aggregation}`];
        displayName = `${aggregation}(${displayName})`;
      } else {
        value = feature?.properties[fieldConfig.name];
      }

      if (value === undefined) value = DOUBLE_DASH;
      if (value !== DOUBLE_DASH && fieldConfig.format !== undefined) {
        if (formatFunctions[fieldConfig.format] === undefined) {
          formatFunctions[fieldConfig.format] = getFormatter(
            fieldConfig.format,
            value,
          );
        }
        value = formatFunctions[fieldConfig.format](value);
      }
      return `<div class="display-name">${displayName}</div><div class="value">${value}</div>`;
    });
    setPopupConfig({
      trigger,
      coordinate,
      innerHTML: `
<div class="content">
  <h3>
    <strong>${layer.props.cartoLabel}</strong>
  </h3>
  ${measures.join('')}
</div>
      `,
    });
  };

  if (layerPopupConfig?.enabled === true) {
    const click = pathOr(null, [MOUSE_EVENTS.CLICK], layerPopupConfig);
    const hover = pathOr(null, [MOUSE_EVENTS.HOVER], layerPopupConfig);
    const clickFields = pathOr([], ['fields'], click);
    const hoverFields = pathOr([], ['fields'], hover);

    if (isArrayNotEmpty(clickFields)) {
      config.onClick = (info) => {
        /**
         * Info Panel
         * layerPopupConfig.click.style === 'panel'
         * other values are variants of 'light' and 'dark'
         */
        if (popupConfigRef.current?.popupCloseClicked !== true) {
          buildInfoHTML(info, MOUSE_EVENTS.CLICK, clickFields);
        } else {
          setPopupConfig(false);
        }
      };
    }
    if (isArrayNotEmpty(hoverFields)) {
      config.onHover = (info) => {
        if (popupConfigRef.current?.trigger !== MOUSE_EVENTS.CLICK) {
          buildInfoHTML(info, MOUSE_EVENTS.HOVER, hoverFields);
        }
      };
    }
  } else {
    config.pickable = false;
  }

  // handle sizeRange
  const sizeRange = pathOr(undefined, ['config', 'visConfig', 'sizeRange'], rawLayer);
  const sizeField = pathOr(undefined, ['visualChannels', 'sizeField'], rawLayer)
  const sizeScale = pathOr(undefined, ['visualChannels', 'sizeScale'], rawLayer)
  if (
    typeof config.getLineWidth == 'number' &&
    sizeRange !== undefined &&
    sizeField !== undefined &&
    sizeScale === 'linear' // only support linear for now
  ) {
    let scalerFn;
    config.getLineWidth = (feature, context) => {
      if (scalerFn === undefined) {
        const domain = Array.from(
          context.data.features,
          (_feature) => pathOr(undefined, ['properties', sizeField.name], _feature)
        );

        scalerFn = scaleLinear().domain(d3Extent(domain)).range(sizeRange);
      }

      const value = pathOr(undefined, ['properties', sizeField.name], feature);
      return (
        typeof value === 'number' && Number.isFinite(value) ?
          scalerFn(value) :
          1
      );
    }
  }

  // replace classic layers with the ones from @deck.gl/aggregation-layers
  const IS_LAYER_WITH_GEOJSON = hasGeoJsonData(rawLayer, GEOJSON_LAYER_MAPPING);
  if (IS_LAYER_WITH_GEOJSON) {
    let _deckLayer = {};
    let geoJsonData = pathOr([], ['props', 'data', 'features'], deckLayer);
    if (isArrayNotEmpty(geoJsonData)) {
      // convert geojson to json
      geoJsonData = geoJsonData.map((q) => [...q?.geometry?.coordinates, 1]);
      // return a new layer layer with the json data
      const geoJSONLayoutProps = {
        data: geoJsonData,
        getPosition: (d) => [d[0], d[1]],
        getWeight: (d) => d[2],
      };
      switch (IS_LAYER_WITH_GEOJSON) {
        case CARTO_MAP_LAYERS.HEATMAP_LAYER:
          _deckLayer = new HeatmapLayer(geoJSONLayoutProps);
          break;
        case CARTO_MAP_LAYERS.CPUGRID_LAYER:
          _deckLayer = new CPUGridLayer(geoJSONLayoutProps);
          break;
        case CARTO_MAP_LAYERS.HEXAGON_LAYER:
          _deckLayer = new HexagonLayer(geoJSONLayoutProps);
          break;
      }
      // clone and delete inherit props
      const _config = { ...config };
      delete _config.getPosition;
      delete _config.getWeight;
      if (IS_LAYER_WITH_GEOJSON === CARTO_MAP_LAYERS.HEATMAP_LAYER) {
        // create filter size, that fixes the zoom/drag disappearing issue
        const dataFilterExtension = new DataFilterExtension({
          filterSize: 4,
        });
        _config.extensions = [dataFilterExtension];
      }
      return _deckLayer.clone(_config);
    }
  }

  return deckLayer.clone(config);
};
